$( document ).ready(function() {

	$( "div.help-support-menu-button" ).addClass("h-50");

});

$( window ).resize(function() {

	if($( document ).width() < 559){

		$( "div.help-support-menu-button" ).removeClass("h-50");

	}else if($( "div.help-support-menu-button" ).hasClass("h-50") == false){

		$( "div.help-support-menu-button" ).addClass("h-50");

	}

});